<template>
  <notification-component />
  <VOnboardingWrapper
    v-if="loginStatus"
    ref="wrapper"
    :steps="steps"
    class="z-50"
  />
  <div
    class="w-full h-full flex"
    v-touch:swipe.left="swipeLeftHandler"
    v-touch:swipe.right="swipeRightHandler"
  >
    <div class="w-full flex-col flex-wrap pt-8 pb-10 min-h-max">
      <router-view />
    </div>
  </div>
  <modal-component />
  <reload-prompt />
</template>

<script setup>
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { useAuth } from "./stores/auth";
import { useLayout } from "./stores/layout";
import ReloadPrompt from "./components/prompts/ReloadPrompt.vue";
import ModalComponent from "./components/layout/modal/ModalComponent.vue";
import NotificationComponent from "./components/notifications/NotificationComponent.vue";
import { VOnboardingWrapper } from "v-onboarding";

const auth = useAuth();
const layout = useLayout();

// Variables
// - Onboarding
const wrapper = ref();
const steps = [
  {
    attachTo: {
      element: ".step-1",
    },
    content: {
      title: "🥳 ¡El equipo de LerenTools te da la bienvenida! 🎉 ✨",
      description:
        "En este panel vas a poder gestionar todas las aplicaciones que instales en tu tienda",
    },
    options: {
      popper: {
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [100, 5],
            },
          },
        ],
      },
    },
  },
  {
    attachTo: {
      element: ".step-2",
    },
    content: {
      title: "Importante 📣",
      description:
        "Haciendo click en este boton vas a poder gestionar las preferencias de tu cuenta y cerrar tu sesión si así lo deseas.",
    },
    options: {
      popper: {
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 5],
            },
          },
        ],
      },
    },
  },
];
// TODO: Deberiamos llevar un registro de qué steps vio el cliente para no empezar siempre del 1
// TODO: Deberiamos registrar cuando el cliente llega al 'Finish' del ultimo paso asi no volvemos a mostrarle el on boarding

// Methods
const startOnBoarding = async () => {
  // TODO: await get onBoardingStatus(auth.user.id) o algo asi para saber si tenemos que mostrar el on boarding o no
  await nextTick(); // Waiting for DOM to render child components

  setTimeout(() => {
    if (wrapper.value && loginStatus) {
      wrapper.value.start();
      // wrapper.value.goToStep(2)
      // wrapper.value.finish()

      // Si ya no necesitamos el watcher
      // loginStatusWatcherStop()
    }
  }, 1000);
};
const swipeLeftHandler = () => {
  layout.closeSidebar();
};
const swipeRightHandler = () => {
  layout.openSidebar();
};

// Computed
const loginStatus = computed(() => auth.loginStatus);
const isSidebarVisible = computed(() => layout.isSidebarVisible);
const onboardingStatus = computed(() => layout.onboardingStatus);

// Watchers
/* const loginStatusWatcherStop =  */ watch(onboardingStatus, startOnBoarding);

// Lifecycle hooks
onMounted(async () => {
  // startOnBoarding(); // TODO: uncomment?
});
</script>
