// Solid Icons
import {
  TrashIcon as solidTrashIcon,
  XMarkIcon as solidXMarkIcon,
} from "@heroicons/vue/24/solid";
// Outline Icons
import {
  TrashIcon as outlineTrashIcon,
  XMarkIcon as outlineXMarkIcon,
} from "@heroicons/vue/24/outline";

export const iconsSet = Object.assign(
  {},
  {
    // Solid Icons
    solidTrashIcon,
    solidXMarkIcon,
    // Outline Icons
    outlineTrashIcon,
    outlineXMarkIcon,
  }
);
