import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("./components/home/HomePage.vue"),
    children: [
      {
        path: "/",
        name: "Dashboard",
        component: () => import("./components/dashboard/DashboardPage.vue"),
      },
      // {
      //   path: "login",
      //   name: "Inicio de sesión",
      //   component: () => import("./components/auth/LoginPage.vue"),
      // },
      // {
      //   path: "register",
      //   name: "Registro",
      //   component: () => import("./components/auth/RegisterPage.vue"),
      // },
      // TODO: ¿Routes?
      {
        path: "not_found",
        name: "¡Ups - Página no encontrada!",
        component: () => import("./components/errors/404Page.vue"),
      },
      {
        path: "fatal_error",
        name: "¡Ups - Ocurrió un error!",
        component: () => import("./components/errors/500Page.vue"),
      },
    ],
  },
  // will match everything and put it under `$route.params.pathMatch`
  {
    path: "/:pathMatch(.*)*",
    redirect: "/not_found",
  },
];

const history = createWebHistory();

const router = createRouter({
  history,
  routes,
});

// router.beforeEach((to, from, next) => {
//   if (to.meta.skipAuth) {
//     next();
//   } else {
//     const loginStatus = Boolean(Number(localStorage.getItem("login_status")));

//     if (to.path === "/login") {
//       if (loginStatus) {
//         next("/");
//       } else {
//         next();
//       }
//     } else {
//       if (loginStatus) {
//         next();
//       } else {
//         if (to.path === "/not_found" || to.path === "/fatal_error") {
//           next();
//         } else {
//           next("/login");
//         }
//       }
//     }
//   }
// });

export default router;
