import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import store from "./store";
import { useAuth } from "./stores/auth";
import axios from "axios";
import './style.css'
import "v-onboarding/dist/style.css";
import "vue-skeletor/dist/vue-skeletor.css";
import { iconsSet } from "./assets/icons/icons.js";
import IconComponent from "./components/icons/IconComponent.vue";
import Vue3TouchEvents from "vue3-touch-events";
import Notifications from "notiwind";

axios.defaults.baseURL = import.meta.env.VITE_API_BASE;
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      (error.response.data.response == "Necesita ser administrador o vendedor para acceder a estos datos." ||
      error.response.data.message == "Token expirado" ||
      error.response.data.message == "Token no valido" ||
      error.response.data.error == "Token expirado" ||
      error.response.data.error == "Token no valido") // TODO: Revisar estas condiciones para que funcionen en este sistema de encuestas
    ) {
      // Log out
      const auth = useAuth();
      await auth.logout();
      // window.location.reload();
    }
    return Promise.reject(error);
  }
);

const loginStatus = false;
const apiToken = "";
if (loginStatus && apiToken) {
  axios.defaults.headers.common["X-API-TOKEN"] = apiToken;
} else {
  axios.defaults.headers.common["X-API-TOKEN"] = null;
}

createApp(App)
  .use(router)
  .use(store)
  .use(Vue3TouchEvents, {
    disableClick: true,
    swipeTolerance: 60,
  })
  .use(Notifications)
  .provide("icons", iconsSet)
  .component("IconComponent", IconComponent)
  .mount('#app')
