import { defineStore } from "pinia";
import axios from "axios";

export const useAuth = defineStore("auth-store", {
  state: () => {
    return {
      loading: false,
      loginStatus: false,
      apiToken: "",
      user: {},
    };
  },

  actions: {
    async login(email, password) {
      // console.log("Login attempt.");
      this.loading = true;

      try {
        const response = await axios.post(
          import.meta.env.VITE_API_BASE + "auth/login",
          { username: email, password: password }
        );

        this.apiToken = response.data.token;
        this.loginStatus = true;

        axios.defaults.headers.common[
          "X-API-TOKEN"
        ] = this.apiToken;

        this.loading = false;

        // console.log("Successfully Logged in.");

        // Intento obtener los datos del User
        try {
          const userResponse = await axios.get(import.meta.env.VITE_API_BASE + "cliente/datos");
          // console.log("Successfully fetched User data.");
          if (userResponse && userResponse.data && userResponse.data.status) {
            this.user = userResponse.data.response;
          }
        } catch (error) {
          console.log("Error fetching User data:", error);
        }

        return true;
      } catch (error) {
        // console.log("Login error:", error)

        axios.defaults.headers.common["X-API-TOKEN"] = null;

        this.loading = false;

        throw error;
      }
    },
    async logout() {
      // console.log("Logout attempt.");
      this.loading = true;

      this.loginStatus = false;
      this.apiToken = "";
      this.user = {};

      axios.defaults.headers.common["X-API-TOKEN"] = null;

      this.loading = false;

      // console.log("Successfully Logged out.");

      return true;
    },
  },
});
