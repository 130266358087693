import { defineStore } from "pinia";

export const useLayout = defineStore("layout-store", {
  state: () => {
    return {
      isMobile: true,
      isSidebarVisible: true,
      isSidebarOpen: false,
      showBackdrop: false,
      onboardingStatus: false,
    };
  },

  actions: {
    async setIsMobile(isMobile) {
      this.isMobile = isMobile;
    },
    async showSidebar() {
      this.isSidebarVisible = true;
    },
    async hideSidebar() {
      this.isSidebarVisible = false;
    },
    async openSidebar() {
      if (!this.isSidebarOpen) {
        this.isSidebarOpen = true;
      }
    },
    async closeSidebar() {
      if (this.isSidebarOpen) {
        this.isSidebarOpen = false;
      }
    },
    async showBackdrop() {
      this.showBackdrop = true;
    },
    async hideBrackdrop() {
      this.showBackdrop = false;
    },
    async startOnboarding() {
      this.onboardingStatus = true;
    }
  },
});
